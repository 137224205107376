*{
    box-sizing: border-box;
    font-family: Urbanist 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.header{
    max-width: 1280px;
    margin: 4rem auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(10 , 1fr);
    grid-gap: 5rem;
}

.headertext{
    grid-column: 1/7;
}

h2{
    font-size: 6rem;
    font-weight: bolder;
}
h5{
    font-size: 3rem;
    font-weight: lighter;
    padding: 1rem 0;
}
.header_tagline{
    color: black;
}
.arrow{
    grid-column: 7/8;
    align-self: self-end;
}
@media only screen and (max-width: 1200px) {
    .header{
        max-width: 900px;
    }
  }

@media only screen and (max-width: 770px) {
    .arrow{
        display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    h2{
        font-size: 3rem;
        font-weight: bolder;
    }
    h5{
        font-size: 1rem;
        font-weight: lighter;
        padding: 1rem 0;
    }
    .header{
        margin: 2rem auto;
        max-width: 350px;
        display: block;
        padding: 0 1rem;
    }
  }
  