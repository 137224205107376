.tools{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    max-width: 1280px;
    margin: 3rem auto;
    justify-content: center;
    z-index: 2;
}
.tool img{
    max-width: 380px;
    max-height: auto;
    margin-top: 1rem;
    border-radius: 1rem;
    border: solid black 2px;
}
@media only screen and (max-width: 1200px) {
    .tool img{
        max-width: 200px;
        height: auto;
        margin: .5rem .5rem;
    }
    .tools{
        max-width: 300px;
    }
  }
  @media only screen and (max-width: 800px) {
    .tools{
        grid-template-columns: repeat(2, 1fr);
    }
    .tool img{
        max-width: 270px;
        height: auto;
        margin: .5rem .5rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .tools{
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .tool img{
        max-width: 300px;
    }
  }