*{
    box-sizing: border-box;
}
.nav{
    display: grid;
    grid-template-columns: repeat(10 , 1fr);
    align-items: center;
    margin: 1.5rem auto;
    max-width: 1280px;
}
.navs{
    text-decoration: none;
    list-style-type: none;
}
a{
    text-decoration: none;
    color: black;
}
.logo img{
    height: 3rem;
    width: auto;
    align-content: left;
}
#button{
    color: white;
    background-color: #456CFA;
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: solid 2px #456CFA;
}
#button:hover{
    color: #456CFA;
    border: solid 2px #456CFA;
    background-color: white;
}
.logo{
    grid-column-start: 1;
    grid-column-end: 3;
}
.menu{
    grid-column-start: 8;
    grid-column-end: 11;
}
ul{
    display: flex;
}
li{
    margin: 0 2rem;
}
@media only screen and (max-width: 1200px) {
    .nav{
        max-width: 900px;
        grid-template-columns:repeat(4 , 1fr) ;
    }
  }
@media only screen and (max-width: 600px) {
    .menu{
        display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    .nav{
        max-width: 350px;
    }
    .drop-email{
        display: flex;
    }
  }