
.footer{
    background-color: black;
    margin: 0;
}
h5,li{
    color: white;
}
.footernav{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 5rem 0;
}
.footernavlinks{
    text-decoration: none;
    list-style-type: none;
    margin: .5rem 0;
    padding: 0 1.5rem;
}
.footerHeading{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 0rem;
}
.footerlinks{
    text-align: left;
    grid-column-start: 2;
    grid-column-end: 4;
}
.emailbutton , .emailbox{
    border: white solid 2px;
    padding: 1rem 2.5rem;
    background-color: black;
    color: white;
}
.emailbutton{
    color: white;
}
.drop-email{
    margin: auto auto;
    justify-content: center;
    grid-column-start: 4;
    grid-column-end: 6;
}
@media only screen and (max-width: 1024px) {
    .footerlinks{
        display: block;
    }
    .emailbutton , .emailbox{
        padding: .5rem 1.5rem;
        margin: auto;
    }
  }
  @media only screen and (max-width: 480px) {
    .footerlinks{
        display: block;
        padding: none;
        grid-column:1/2;
        text-align: center;
    }
    .emailbutton , .emailbox{
        padding: .5rem .8rem;
        margin: auto;
    }
    .footernav{
        display: block;
        max-width: 350px;
        margin: auto;
    }
  }